import { useCallback } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { useToast } from "../state/hooks";
import { connectorsByName } from "../utils/web3React";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const useAuth = () => {
  const { toastError } = useToast();

  const TomiPayBol = localStorage.getItem('IsTomipay')

  const login = async (connectorID) => {
    const connector = connectorsByName[connectorID];
    const normalizedNetwork = process.env.REACT_APP_NETWORK?.trim().toUpperCase();
    const chainId = parseInt(process.env[`REACT_APP_${normalizedNetwork}_CHAIN_ID`], 10);
    if (connector) {
      // // console.log("connector??????", connector)
      if (connectorID === 'injected') {
        await connector.activate(chainId)
      } else {
        await connector.activate()
        // if (TomiPayBol == 'false') {
        //   // console.log("here i trust", connector)
        //   await connector.activate()
        // } else {
        //   // console.log("here i tomi ", connector)
        //   setTimeout(async () => {
        //     let ab = await connector?.provider?.signer?.uri
        //     // console.log("providerNullproviderNullproviderNull", ab)
        //     // alert(ab)
        //     if (ab == undefined) {
        //       localStorage.setItem('provider', 'true');
        //     }
        //     else {
        //       localStorage.setItem('provider', 'false');
        //     }
        //     // alert(ab)
        //     window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(ab);
        //   }, 3000)
      }
    } else {
      toastError("Can't find connector", "The connector config is wrong");
    }
  }

  

  const logout = async (connectorID) => {
    // // console.log("disssss", connectorID)
    const connector = connectorsByName[connectorID];
    // // console.log("disssss", connector)
    if (connector) {
      if (connector?.deactivate) {

        await connector.deactivate()
      } else {
        await connector.resetState()
      }
      // await connector.deactivate()
    } else {
      toastError("Can't find connector", "The connector config is wrong");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return { login, logout };
};

export default useAuth;

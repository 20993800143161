import random from 'lodash/random'

// Array of available nodes to connect to
const normalizedNetwork = process.env.REACT_APP_NETWORK?.trim().toUpperCase();

export const nodes = [
  process.env[`REACT_APP_${normalizedNetwork}_NODE_1`],
  process.env[`REACT_APP_${normalizedNetwork}_NODE_2`],
  process.env[`REACT_APP_${normalizedNetwork}_NODE_3`],
];


const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1)
  return nodes[randomIndex]
}

export default getNodeUrl

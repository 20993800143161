const normalizedNetwork = process.env.REACT_APP_NETWORK?.trim().toUpperCase();

const Environment = normalizedNetwork === "MAINNET"
  ? {
    tastManager: "0x29ac7D0C763c4903358872Fd3326B3d0468104F9",
    busd: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    tomi: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
    eth: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    usdc: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    wbtc: "0x54410d42628C7fD6720C489219033345Bcb7Cc73",
    link: "0x37bBd68526C9BDb49f18fB36983701E344f2A163",
    pepe: "0x0592EBa69Aa5f2eF68d9cb740fBaB2c0c0CC6A91",
    unisape: "0x079c46A5Ba477400a326E03Ad8D22FaBeaE57b4F",
    gems: "0xB297D4b01b286de35Ee12cdCe89Ec9488EEec0C2",
    dop: "0xa048E46C35cf210bB0d5bb46b2DD06828Ef17893",
    //createTask Tokens
    ETH: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    trxHashUrl: 'https://etherscan.io'
  }
  : {
    tastManager: "0x9309061bA9991e9E01555637bD5b3b4b1fDa72CF",
    busd: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    tomi: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
    eth: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    usdc: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    wbtc: "0x54410d42628C7fD6720C489219033345Bcb7Cc73",
    link: "0x37bBd68526C9BDb49f18fB36983701E344f2A163",
    pepe: "0x0592EBa69Aa5f2eF68d9cb740fBaB2c0c0CC6A91",
    unisape: "0x079c46A5Ba477400a326E03Ad8D22FaBeaE57b4F",
    gems: "0xB297D4b01b286de35Ee12cdCe89Ec9488EEec0C2",
    dop: "0xa048E46C35cf210bB0d5bb46b2DD06828Ef17893",
    //createTask Tokens
    ETH: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    USDT: '0x6fEA2f1b82aFC40030520a6C49B0d3b652A65915',
    USDC: '0x25762231808F040410586504fDF08Df259A2163c',
    trxHashUrl: 'https://sepolia.etherscan.io'
  };

export default Environment;

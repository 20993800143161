import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { MAINNET_CHAINS } from '../chains'

const [mainnet, ...optionalChains] = Object.keys(MAINNET_CHAINS).map(Number)
const TomiPayBol = localStorage.getItem('IsTomipay')
// alert(TomiPayBol)
export const [walletConnectV3, hooks] = initializeConnector(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: "41575c533b5af1d59c41d018c9ae60d9",
        chains: [1],
        optionalChains: [4],
          showQrModal: false
        // showQrModal: TomiPayBol ==true || TomiPayBol==null ? false : true
      },
      timeout : 10000,
      onError: (err => {
        // console.log('erron in connector::::', err)
      })
    })
)
import './app.scss';
// import Editprofile from './components/Editprofile/Editprofile.js';
import Footer from './components/landing/footer/Footer.js';
import Landing from './components/landing/Landing.js';
// import Wardah from './components/Wardah/Wardah.js';
// import Createtask from './components/Createtask/Createtask.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Taskscreated from './components/Taskscreated/Taskscreated.js';
// import Tasksdetails from './components/Tasksdetails/Tasksdetails.js';
// import Similartaskdetail from './components/Similartaskdetail/Similartaskdetail.js';
// import LatestTask from './components/landing/LatestTask/LatestTask.js';
// import Raza from './components/Raza/Raza.js';
// import Taskparticipated from './components/Taskparticipated/Taskparticipated.js';
import useEagerConnect from "./hooks/useEagerConnect";
import { ToastContainer } from 'react-toastify';
// import PerviewTask from './components/Createtask/perviewTask.js';
import './dist/quill.snow.css'
import Coming from './components/Comingsoon/Coming.js';
// import Privacy from './components/Privacy/Privacy.js';
// import Terms from './components/Terms/Terms.js';
// import Faqs from './components/Faqs/Faqs.js';

function App() {
  useEagerConnect();
  return (
    <>
     <ToastContainer autoClose={5000} style={{ fontSize: 12, fontWeight: 300 }} theme="light" position="top-center" />
          <Router>
            <Routes>
              <Route exact path='/' element={<Landing />} />
              <Route exact path='/comingsoon' element={<Coming />} />
              {/* <Route exact path='/taskscreated' element={<Taskscreated />} />
              <Route exact path='/tasksdetails/:id' element={<Tasksdetails />} />
              <Route exact path='/similartaskdetail/:id' element={<Similartaskdetail />} />
              <Route exact path='/taskperview' element={<PerviewTask />} />
              <Route exact path='/raza' element={<Raza />} />
              <Route exact path='/wardah' element={<Wardah />} />
              <Route exact path='/createtask' element={<Createtask />} />
              <Route exact path='/editprofile' element={<Editprofile />} />
              <Route exact path='/latesttask' element={<LatestTask />} />
              <Route exact path='/taskparticipate' element={<Taskparticipated />} />
              <Route exact path='/privacy-policy' element={<Privacy />} />
              <Route exact path='/terms-of-use' element={<Terms />} />
              <Route exact path='/faqs' element={<Faqs />} /> */}
            </Routes>
            <Footer />
          </Router>
    </>
  );
}

export default App;

import React, { useState } from "react";
import "./join.scss";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../../../utils/ApiUrl";
import { toast } from "react-toastify";

const Join = () => {
  const [show, setShow] = useState(false);
  const [emailForWait, setEmailForWait] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const handleClose = () => setShow(false);

  const handleSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(emailForWait) && isChecked) {
      setIsValidEmail(true);

      const payload = {
        email: emailForWait,
        isAgreed: isChecked,
      };

      try {
        // Making the POST request using axios
        const response = await axios.post(`${API_URL}wait-list`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          setShow(true); // Show success message
          setEmailForWait(""); // Clear email input
        }
      } catch (error) {
        if (error?.response && error?.response?.status == 409) {
          setEmailErrorMessage("Email already exists in the waitlist.");
          setIsValidEmail(false); // Set email as invalid
          // toast.error("Email already exists in the waitlist."); //
        } else if (error?.response) {
          toast.error(
            "An error occurred while submitting your request. Please try again."
          ); // Show error toast on failure
        }
      }
    } else {
      setIsValidEmail(false); // Set email as invalid
      isChecked
        ? setEmailErrorMessage("Please enter a valid email address.")
        : setEmailErrorMessage(
            "Please agree to receive updates and promotional emails to join the waitlist."
          ); // Show error message
    }
  };

  const handleEmailChange = (e) => {
    setEmailForWait(e.target.value);
    setIsValidEmail(true); // Reset the validity when typing
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle checkbox state
  };

  return (
    <>
      <section className="join-section" id="join">
        <img
          src="\assets\join\join-bg.png"
          alt="img"
          className="img-fluid join-bg"
        />
        <div className="custom-container">
          <div className="parent">
            <div className="main-content">
              <h4>
                Join Tasks: <br />
                The Future of Work Awaits
              </h4>
              <p>
                Be among the first to experience a secure, fair, and transparent
                platform for rewarding work.
              </p>
            </div>
            <div className="join-waitlist">
              <h6>
              Sign up today for exclusive updates.
              </h6>
              <div className="option-field">
                <input
                  type="text"
                  placeholder="Enter your email address"
                  className={` ${!isValidEmail ? "errorr" : ""}`} // Apply error class if invalid
                  value={emailForWait}
                  onChange={handleEmailChange}
                />
                {!isValidEmail && (
                  <p className="error-textt ">
                    {emailErrorMessage}
                    {/* Please enter a valid email address. */}
                  </p>
                )}
                <a onClick={handleSubmit} className="btn-join">
                  Join the Waitlist
                </a>
              </div>
              {!isValidEmail && (
                <p className="error-textt ">{emailErrorMessage}</p>
              )}
              <div className="custom-checkbox">
                <div className="form-group">
                  <input
                    type="checkbox"
                    id="html"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="html">
                    Please agree to receive updates and promotional emails to
                    join the waitlist.
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="connectwallet-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Join the Waitlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="joinwait-modal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
            >
              <rect
                x="5"
                y="5"
                width="60"
                height="60"
                stroke="#013159"
                strokeWidth="2"
              />
              <rect
                x="28.2031"
                y="49.8573"
                width="2"
                height="40.2235"
                transform="rotate(-135 28.2031 49.8573)"
                fill="#013159"
              />
              <rect
                x="17"
                y="38.625"
                width="2"
                height="15.43"
                transform="rotate(-45 17 38.625)"
                fill="#013159"
              />
            </svg>
            <h6>Thank you for registration</h6>
            <a onClick={handleClose}>Close</a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Join;

import React from 'react'
import "./coming.scss"

const Coming = () => {
    return (
        <>
            <section className="coming-soon">
                <img src="\assets\coming\coming-bg.png" alt="img" className='img-fluid coming-bg' />
                <div className="custom-container">
                    <div className="main-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="173" height="50" viewBox="0 0 173 50" fill="none">
                            <path d="M9.15922 5.05615L6.25977 0H25.2524L25.2017 5.05615H9.15922Z" fill="#013159" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.26074 14.6445L9.1602 9.58838H25.2026L25.25 14.3042L25.254 14.3043V39.0932L20.1978 41.9927L20.1978 14.6445H6.26074Z" fill="#013159" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.7003 14.6445L45.8008 9.58838H29.7584L29.711 14.3042L29.707 14.3043L29.707 39.0932L34.7632 41.9927L34.7632 14.6445H48.7003Z" fill="#013159" />
                            <path d="M45.8017 5.05615L48.7012 0H29.7085L29.7593 5.05615H45.8017Z" fill="#013159" />
                            <path d="M48.7 7.54236L51.5298 12.4574L54.459 7.54236L51.5298 2.52802L48.7 7.54236Z" fill="#013159" />
                            <path d="M27.8018 43.9371L22.8867 46.767L27.8018 49.6962L32.8161 46.767L27.8018 43.9371Z" fill="#013159" />
                            <path d="M0.500737 7.54236L3.33059 12.4574L6.25977 7.54236L3.33059 2.52802L0.500737 7.54236Z" fill="#013159" />
                            <path d="M40.5674 20.1881H48.7011L40.5674 40.2629V20.1881Z" fill="#013159" />
                            <path d="M14.3945 20.1881H6.26076L14.3945 40.2629V20.1881Z" fill="#013159" />
                            <path d="M77.206 41.7761V13.0289H66.916V9.47583H91.3719V13.0289H81.082V41.7761H77.206Z" fill="#013159" />
                            <path d="M105.549 41.7761L105.364 37.9924V29.5943C105.364 27.7793 105.164 26.2874 104.764 25.1184C104.364 23.9187 103.734 23.0112 102.872 22.3959C102.011 21.7807 100.888 21.4731 99.504 21.4731C98.2427 21.4731 97.1507 21.7346 96.2278 22.2575C95.3357 22.7497 94.5974 23.5495 94.0129 24.657L90.6906 23.3649C91.2751 22.2883 91.9826 21.3654 92.8132 20.5964C93.6438 19.7965 94.6128 19.1967 95.7202 18.7968C96.8277 18.3661 98.0889 18.1508 99.504 18.1508C101.657 18.1508 103.426 18.5814 104.81 19.4428C106.225 20.2733 107.287 21.5192 107.994 23.1804C108.702 24.8108 109.04 26.8411 109.009 29.2713L108.963 41.7761H105.549ZM98.6272 42.3298C95.9202 42.3298 93.7976 41.7146 92.2595 40.4841C90.7521 39.2228 89.9985 37.4848 89.9985 35.2699C89.9985 32.932 90.7675 31.1478 92.3056 29.9173C93.8745 28.656 96.0586 28.0254 98.858 28.0254H105.456V31.117H99.6885C97.5352 31.117 95.9971 31.4708 95.0742 32.1783C94.1821 32.8858 93.7361 33.901 93.7361 35.2238C93.7361 36.4235 94.1821 37.3771 95.0742 38.0846C95.9663 38.7614 97.2122 39.0998 98.8118 39.0998C100.135 39.0998 101.288 38.8229 102.273 38.2692C103.257 37.6847 104.011 36.8695 104.534 35.8236C105.087 34.7469 105.364 33.4857 105.364 32.0399H106.933C106.933 35.1776 106.225 37.6847 104.81 39.5612C103.395 41.407 101.334 42.3298 98.6272 42.3298Z" fill="#013159" />
                            <path d="M122.011 42.3298C120.319 42.3298 118.843 42.0837 117.581 41.5915C116.32 41.0686 115.259 40.3918 114.397 39.5612C113.567 38.7306 112.967 37.8232 112.598 36.8388L115.828 35.5006C116.351 36.5465 117.135 37.3925 118.181 38.0385C119.227 38.6845 120.381 39.0075 121.642 39.0075C123.088 39.0075 124.303 38.7306 125.287 38.1769C126.272 37.5924 126.764 36.7773 126.764 35.7313C126.764 34.7777 126.395 34.0087 125.656 33.4242C124.949 32.8397 123.888 32.3629 122.472 31.9937L120.258 31.3939C118.166 30.8402 116.535 30.0096 115.366 28.9021C114.197 27.7639 113.613 26.4873 113.613 25.0722C113.613 22.8881 114.321 21.1962 115.736 19.9965C117.181 18.766 119.319 18.1508 122.149 18.1508C123.503 18.1508 124.734 18.3507 125.841 18.7506C126.948 19.1505 127.902 19.7196 128.702 20.4579C129.502 21.1655 130.086 22.0114 130.455 22.9958L127.225 24.4262C126.825 23.3803 126.164 22.6267 125.241 22.1652C124.318 21.7038 123.195 21.4731 121.873 21.4731C120.458 21.4731 119.35 21.7807 118.55 22.3959C117.75 22.9804 117.351 23.811 117.351 24.8877C117.351 25.5029 117.674 26.0874 118.32 26.6411C118.996 27.1641 119.981 27.5947 121.273 27.9331L123.58 28.533C125.087 28.9021 126.349 29.4559 127.364 30.1941C128.379 30.9017 129.148 31.7169 129.671 32.6397C130.194 33.5626 130.455 34.547 130.455 35.5929C130.455 36.9772 130.086 38.1769 129.348 39.1921C128.61 40.2072 127.61 40.9917 126.349 41.5454C125.087 42.0683 123.641 42.3298 122.011 42.3298Z" fill="#013159" />
                            <path d="M138.107 34.347L135.753 31.8092L148.028 18.7045H152.688L138.107 34.347ZM134.508 41.7761V9.47583H138.199V41.7761H134.508ZM149.043 41.7761L140.275 28.533L142.675 25.949L153.611 41.7761H149.043Z" fill="#013159" />
                            <path d="M164.055 42.3298C162.364 42.3298 160.887 42.0837 159.626 41.5915C158.364 41.0686 157.303 40.3918 156.442 39.5612C155.611 38.7306 155.011 37.8232 154.642 36.8388L157.872 35.5006C158.395 36.5465 159.18 37.3925 160.226 38.0385C161.272 38.6845 162.425 39.0075 163.686 39.0075C165.132 39.0075 166.347 38.7306 167.332 38.1769C168.316 37.5924 168.808 36.7773 168.808 35.7313C168.808 34.7777 168.439 34.0087 167.701 33.4242C166.993 32.8397 165.932 32.3629 164.517 31.9937L162.302 31.3939C160.21 30.8402 158.58 30.0096 157.411 28.9021C156.242 27.7639 155.657 26.4873 155.657 25.0722C155.657 22.8881 156.365 21.1962 157.78 19.9965C159.226 18.766 161.364 18.1508 164.194 18.1508C165.547 18.1508 166.778 18.3507 167.885 18.7506C168.993 19.1505 169.946 19.7196 170.746 20.4579C171.546 21.1655 172.131 22.0114 172.5 22.9958L169.27 24.4262C168.87 23.3803 168.208 22.6267 167.286 22.1652C166.363 21.7038 165.24 21.4731 163.917 21.4731C162.502 21.4731 161.395 21.7807 160.595 22.3959C159.795 22.9804 159.395 23.811 159.395 24.8877C159.395 25.5029 159.718 26.0874 160.364 26.6411C161.041 27.1641 162.025 27.5947 163.317 27.9331L165.624 28.533C167.132 28.9021 168.393 29.4559 169.408 30.1941C170.423 30.9017 171.192 31.7169 171.715 32.6397C172.238 33.5626 172.5 34.547 172.5 35.5929C172.5 36.9772 172.131 38.1769 171.392 39.1921C170.654 40.2072 169.654 40.9917 168.393 41.5454C167.132 42.0683 165.686 42.3298 164.055 42.3298Z" fill="#013159" />
                        </svg>
                        <h4>is Launching Soon!</h4>
                        <h6>Sign up today and stay ahead of the curve with exclusive updates.</h6>
                        <div className="option-field">
                            <input type="text" placeholder='Enter your email address' />
                            <p className="error-textt d-none">Please enter a valid email address.</p>
                            <a className='btn-join'>Join the Waitlist</a>
                        </div>
                        <div className="custom-checkbox">
                            <div class="form-group">
                                <input type="checkbox" id="html" />
                                <label for="html">Please agree to receive updates and promotional emails to join the waitlist.</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Coming